import React, { useState } from 'react';
import { Box, Container, TextField, Button } from '@mui/material';

function PasswordPage(props) {
  const [password, setPassword] = useState('');

  function handlePasswordChange(event) {
    const value = event.target.value;
    setPassword(value);
    props.onPasswordChange(value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (password === 'pope') {
      props.onPasswordSubmit(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Container maxWidth="md">
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, backgroundColor: 'white', padding: '100px', borderRadius: '20px' }}>
          <TextField type="password" label="Password" variant="outlined" value={password} onChange={handlePasswordChange} 
            sx={{

              '& label.Mui-focused': {
                color: 'darkred', // Change to the color you want for the focused state
              },

              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#c40e0e', // Replace 'desiredBorderColor' with your color code
                },
                '&:hover fieldset': {
                  borderColor: 'darkred', // Replace 'hoverBorderColor' with your color code
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'darkred', // Replace 'focusedBorderColor' with your color code
                },
              },
            }}
          
          />
          <Button type="submit" variant="contained"
              sx={{
                backgroundColor: '#c40e0e', // This sets the button color to red
                color: 'white', // This sets the text color to white
                '&:hover': {
                  backgroundColor: 'darkred', // Darker red on hover
                },
              }}
          
          >Submit</Button>
        </Box>
      </Container>
    </Box>
  );
}

export default PasswordPage;