import React, { useState } from 'react';
import PasswordPage from './components/PasswordPage';
import QuestionPage from './components/QuestionPage';
import './App.css'

function App() {
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [password, setPassword] = useState('');

  function handlePasswordSubmit(isCorrect) {
    setIsPasswordCorrect(isCorrect);
  }

  function handlePasswordChange(value) {
    setPassword(value);
  }

  return (
    <div className="App">
      {isPasswordCorrect ? <QuestionPage password={password} /> : <PasswordPage onPasswordSubmit={handlePasswordSubmit} onPasswordChange={handlePasswordChange} />}
    </div>
  );
}

export default App;