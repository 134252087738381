import React, { useState } from 'react';
import { Box, Container, Typography, TextField, Button, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

const url = "https://qotd-api.azurewebsites.net/api/qotd-api?code=X0l_JkJiCQeFH-P3EKTU5ZmsinWo3Igazlx1DJRAeOLrAzFudx_JQw==";

function QuestionPage(props) {
  const [name, setName] = useState('');
  const [questions, setQuestions] = useState(['']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleNameChange(event) {
    setName(event.target.value);
  }

  function handleQuestionChange(index, event) {
    const newQuestions = [...questions];
    newQuestions[index] = event.target.value;
    setQuestions(newQuestions);
  }

  function handleAddQuestion() {
    setQuestions([...questions, '']);
  }

  function handleDeleteQuestion(index) {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsSubmitting(true);

    const data = {
      password: props.password,
      name: name,
      questions: questions.filter((question) => question !== ''),
    };
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      alert("Questions saved successfully");
      setQuestions([""]);
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while saving your questions");
    }
  }

  const isSubmitDisabled = questions.some((question) => question === '') || isSubmitting;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc( 100vh - 300px )', marginTop: '50px', marginBottom: '100px' }}>
      <Container maxWidth="md" sx= {{height: '100%'}}>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2,  backgroundColor: 'white', padding: '100px', borderRadius: '20px' }}>
          <TextField type="text" label="Name" variant="outlined" value={name} onChange={handleNameChange} 
                        sx={{

                          '& label.Mui-focused': {
                            color: 'darkred', // Change to the color you want for the focused state
                          },

                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#c40e0e', // Replace 'desiredBorderColor' with your color code
                            },
                            '&:hover fieldset': {
                              borderColor: 'darkred', // Replace 'hoverBorderColor' with your color code
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'darkred', // Replace 'focusedBorderColor' with your color code
                            },
                          },
                        }}
          
          />
          {questions.map((question, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField type="text" label={`Question ${index + 1}`} variant="outlined" value={question} onChange={(event) => handleQuestionChange(index, event)} 
              
              sx={{
                '& label.Mui-focused': {
                  color: 'darkred', // Change to the color you want for the focused state
                },
                flexGrow: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#c40e0e', // Replace 'desiredBorderColor' with your color code
                  },
                  '&:hover fieldset': {
                    borderColor: 'darkred', // Replace 'hoverBorderColor' with your color code
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'darkred', // Replace 'focusedBorderColor' with your color code
                  },
                },
              }}/>
              {index > 0 && <IconButton onClick={() => handleDeleteQuestion(index)}><Delete /></IconButton>}
            </Box>
          ))}
          <Button type="button" variant="contained" onClick={handleAddQuestion}
          sx={{
            backgroundColor: '#c40e0e', // This sets the button color to red
            color: 'white', // This sets the text color to white
            '&:hover': {
              backgroundColor: 'darkred', // Darker red on hover
            },
          }}>Add another question</Button>
          <Button type="submit" variant="contained" disabled={isSubmitDisabled}
          sx={{
            backgroundColor: '#c40e0e', // This sets the button color to red
            color: 'white', // This sets the text color to white
            '&:hover': {
              backgroundColor: 'darkred', // Darker red on hover
            },
          }}>Submit questions</Button>
        </Box>
      </Container>
    </Box>
  );
}

export default QuestionPage;
